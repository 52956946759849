import { PlaylistTrack, Track } from '../api'
import FileSaver from 'file-saver'

type ExportToCSV = (
  tracks: { title: string; url: string; author: string }[]
) => Uint8Array

const fetchWasm = async (): Promise<ExportToCSV> => {
  if (typeof window !== 'undefined') {
    const { getCSVWasm } = require('./getWasm')
    const wasm = await getCSVWasm()
    return wasm.export_to_csv
  }
}

export const generateCSV = async (
  title: string,
  tracks: PlaylistTrack[]
): Promise<void> => {
  const exportToCsv = await fetchWasm()
  console.log({ title, tracks })
  const data = exportToCsv(tracks.map((t) => ({ ...t, artist: t.author })))
  FileSaver.saveAs(new Blob([data]), `${title}.csv`)
}
